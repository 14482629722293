import React from 'react'
import './Schedule.css'
export default function Schedule() {
  return (
    <div id="Schedule">
    <table>
      <p>
        <h3>Saturday, April 6</h3>

          <b>10:00 AM</b> ->              Check-In Opens: Irvine 194<br/>
          <b>11:00 AM</b> ->              Lunch & Opening Ceremony: Irvine 194<br/>
          <b>11:30 AM</b> ->              Hacking Begins<br/>
          <b>11:30 AM</b> ->              Team Formation: Irvine 194<br/>
          <b>12:00 PM - 12:30 PM</b> ->   BobcatCTF Kick Off & Intro Session: ARC 102<br/>
          <b>1:00 PM - 1:45 PM</b> ->     GitHub Copilot Tutorial Workshop: ARC 102<br/>
          <b>2:00 PM - 3:00 PM</b> ->     Resume Workshop with Alex Williams: ARC 102<br/>
          <b>3:00 PM - 4:00 PM</b> ->     Hackathon Organization Tutorial with Drew Mullett: ARC 102<br/>
          <b>4:30 PM - 5:30 PM</b> ->     Easter Egg Hunt: ARC Atrium<br/>
          <b>6:00 PM</b> ->               Dinner: ARC Atrium<br/>
          <b>9:00 PM</b> ->               Excel Speedrunning: ARC 102<br/><br/>

          <h3>Sunday, April 7</h3>
          <b>12:00 AM</b> ->         Midnight Snack (Insomnia Cookies): ARC Atrium<br/>
          <b>1:00 AM</b> ->          Paper Airplanes: ARC Atrium<br/>
          <b>8:00 AM</b> ->          Breakfast<br/>
          <b>11:30 AM</b> ->         Hacking Ends & Judging Begins<br/>
          <b>12:00 PM</b> ->         Lunch<br/>
          <b>2:00 PM</b> ->          Closing Ceremony Begins: Irvine 194<br/>
      </p>
    </table>

  </div>

  )
}
